<template>
	<div class="ele-body">
		<el-card shadow="never">
			<el-form :model="table.where" class="ele-form-search d-flex" @keyup.enter.native="$refs.table.reload()"
				@submit.native.prevent>
				<el-form-item>
					<el-button @click="face_edit_status=true" class="ele-btn-icon addbtn" size="small"
						v-if="permission.includes('sys:notice:add')">添加设置</el-button>
				</el-form-item>
			</el-form>
			<ele-data-table ref="table" :config="table" :choose.sync="choose" height="calc(100vh - 315px)"
				highlight-current-row :stripe=true>
				<template slot-scope="{index}">
					<el-table-column type="selection" width="45" align="center" fixed="left" />
					<el-table-column type="index" :index="index" label="编号" width="60" align="center" fixed="left"
						show-overflow-tooltip />
					<el-table-column prop="auth_type" label="检测对象" show-overflow-tooltip min-width="120" />
					<el-table-column prop="auth_area" label="所属地区" show-overflow-tooltip min-width="120" />
					<el-table-column prop="face_time" label="检测时间" show-overflow-tooltip min-width="120" />
					<el-table-column prop="update_time" label="更新时间" show-overflow-tooltip min-width="160">
						<template slot-scope="{row}">{{ row.update_time*1000 | toDateString }}</template>
					</el-table-column>
					<el-table-column prop="status_name" label="当前状态" show-overflow-tooltip min-width="120" />
<!--					<el-table-column prop="open_name" label="检测状态" show-overflow-tooltip min-width="120"/>-->
					<el-table-column label="操作" width="200px" align="center" :resizable="false" fixed="right">
						<template slot-scope="{row}">
							<el-link @click="face_edit(row)" icon="el-icon-edit" type="primary" :underline="false"
								v-if="permission.includes('sys:face_config:edit')">编辑</el-link>
							<el-link slot="reference" @click="face_close(row)" icon="el-icon-error" type="danger"
								:underline="false"
								v-if="row.status=='1' && permission.includes('sys:face_config:close')">
								关闭
							</el-link>
							<el-link slot="reference" @click="face_start(row)" icon="el-icon-s-claim" type="primary" :underline="false"
								v-if="row.status=='0' && permission.includes('sys:face_config:start')">
								开启
							</el-link>
						</template>
					</el-table-column>
				</template>
			</ele-data-table>
		</el-card>
		<!-- 司机人脸检测时间修改 -->
		<!-- <el-dialog v-dialogDrag :title="editForm.id?'司机人脸检测修改':'司机人脸检测添加'" :visible.sync="face_edit_status" @closed="editForm={}"
			:destroy-on-close="true" custom-class="ele-dialog-form" :lock-scroll="false"> -->
		<el-dialog v-dialogDrag :title="editForm.id?'司机人脸检测修改':'司机人脸检测添加'" @closed="editForm={}" :visible.sync="face_edit_status"
			:destroy-on-close="true" :lock-scroll="false">
			<el-card shadow="never">
				<el-form :model="editForm" ref="editForm" label-width="140px" :rules="editRules">
					<el-form-item label="检测对象:" prop="type">
						<!-- 修改为默认值为固定 -->
					<el-select v-model="editForm.type" placeholder='请选择' clearable>
						<el-option v-for="option in typeArr " :value="option.type" :key="option.type" :label="option.name"></el-option>
					</el-select>
					</el-form-item>
					<div>
						<el-form-item label="选择省市区：">
							<el-select v-model="editForm.pname" @change="handleChangeProv1(editForm.pname)"
								class="selectStyle mr-10 mb-20" clearable placeholder='请选择省'>
								<el-option v-for="option in provArr" :value="option.pid" :key="option.pid"
									:label="option.name"></el-option>
							</el-select>
							<el-select v-model="editForm.cname" @change="handleChangeCity1(editForm.cname)"
								class="selectStyle mr-10 mb-20" clearable placeholder="请选择市">
								<el-option v-for="option in cityArr" :value="option.cid" :key="option.cid"
									:label="option.name"></el-option>
							</el-select>
							<el-select v-model="editForm.aname" @change="$forceUpdate(),handleDis(editForm.aname)"
								class="selectStyle mr-10 mb-20" clearable placeholder="请选择县/区">
								<el-option v-for="option in districtArr " :value="option.aid" :key="option.aid"
									:label="option.name"></el-option>
							</el-select>
						</el-form-item>
					</div>
					<el-form-item label="检测时间:" prop="face_time">
						<el-input v-model="editForm.face_time" style="width:240px"></el-input><span
							style="margin-left: 2rem;font-size: 14px">具体检测时间以英文逗号隔开</span>
					</el-form-item>
          <el-form-item label="是否开启检测接口:" prop="open_api" class="item_switch" style="display: none;">
            <el-switch @change="changeStatus($event)" v-model="editForm.open_api" active-text="开启" inactive-text="关闭">
            </el-switch>
          </el-form-item>
				</el-form>
			</el-card>
			<div slot="footer">
				<el-button @click="face_edit_status=false">取消</el-button>
				<el-button type="primary" @click="save">保存</el-button>
			</div>
		</el-dialog>

	</div>
</template>

<script>
	import {
		mapGetters
	} from "vuex";

	export default {
		name: "SysUser",
		data() {
			return {
				table: {
					url: '/driver/face_list',
					where: {}
				}, // 表格配置
				choose: [], // 表格选中数据
				showEdit: false, // 是否显示表单
				editForm: {
					type:"2"
				}, // 表单数据
				editRules: { // 表单验证规则
					face_time: [{
						required: true,
						message: '请输入检测时间',
						trigger: 'blur'
					},{
						pattern: /^((20|21|22|23|[0-1]\d)[:|：][0-5]\d+,)*((20|21|22|23|[0-1]\d)[:|：][0-5]\d)+$/, 
						message: '请输入正确的检测时间，并用英文逗号(,)隔开', 
						trigger: 'blur',
					}],
					type: [{
						required: true,
						message: '请选择检测对象',
						trigger: 'change'
					}],
					pid: [{
						required: true,
						message: '请选择省份',
						trigger: 'change'
					}]
				},
				typeArr: [
					// {
					// 	type:"1",
					// 	name:'咱县打车-司机版'
					// },
					{
						type:"2",
						name:'可蚁点-司机版'
					}
				],
				provArr: [],
				cityArr: [],
				districtArr: [],
				face_edit_status: false,
				face_add_status: false,
				faceStart: '开启',
				pid: '',
				cid: '',
				aid: '',
				faceType: ''
			}
		},
		created() {

			this.$http.get('/common/province_list').then(res => {
				var data = JSON.parse(res.data)
				this.provArr = data

			})


		},
		computed: {
			...mapGetters(["permission"]),
		},

		mounted() {},
		methods: {
			/**
			 *选择省
			 **/
			handleChangeProv(e) {
				/** 获取被选省份的pid**/
				var pid = ''
				this.provArr.forEach(function(item) {
					if (item.pid == e) {
						pid = item.pid
					}
				})
				/** 根据被选省份的pid获取省市下面的市**/
				this.$http.post('/common/city_list', {
					pid: pid
				}).then(res => {
					var data = JSON.parse(res.data)
					this.cityArr = data
				})
			},
			/**
			 *选择市
			 **/
			handleChangeCity(e) {
				if(e){
				/** 获取被选市的cid**/
				var cid = ''
				this.cityArr.forEach(function(item) {
					if (item.cid == e) {
						cid = item.cid
					}
				})
				/** 根据被选市的cid获取市下面的县**/
				this.$http.post('/common/area_list', {
					cid: cid
				}).then(res => {
					var data = JSON.parse(res.data)
					this.districtArr = data
					/** 选择省份清空县**/
					this.table.where.aid = ''
				})
				}else{
					this.table.where.pid = ''
					this.table.where.aid = ''
				}
			},
			handleChangeProv1(e) {
				/** 获取被选省省份的pid**/
				this.changePro = true
				let pid = ''
				this.provArr.forEach(function(item) {
					if (item.pid == e) {
						pid = item.pid
					}
				})
				/** 根据被选省份的pid获取省市下面的市**/
				this.$http.post('/common/city_list', {
					pid: pid
				}).then(res => {
					let data = JSON.parse(res.data)
					this.cityArr = data
					/** 选择省份清空市县**/
					this.editForm.cname = ''
					this.editForm.aname = ''
				})

			},
			/**
			 *选择市
			 **/

			handleChangeCity1(e) {
				if(e){
				/** 获取被选市的cid**/
				this.changePro = true
				let cid = ''
				this.cityArr.forEach(function(item) {
					if (item.cid == e) {
						cid = item.cid
					}
				})
				/** 根据被选市的cid获取市下面的县**/
				this.$http.post('/common/area_list', {
					cid: cid
				}).then(res => {
					let data = JSON.parse(res.data)
					this.districtArr = data
					/** 选择省份清空县**/
					this.editForm.aname = ''

				})
				}else{
					this.editForm.pname = ''
					this.editForm.aname = ''
				}
			},
			handleDis() {
				this.changePro = true
			},
			/**
			 * 显示编辑
			 */
			face_edit(row) {
				this.face_edit_status = true
				this.editForm = row;
			},
			/**
			 * 保存编辑
			 */
			save() {
				this.$refs['editForm'].validate((valid) => {
					if (valid) {
						const loading = this.$loading({
							lock: true
						});
						if (this.changePro == true) {
							this.editForm.pid = this.editForm.pname
							this.editForm.cid = this.editForm.cname
							this.editForm.aid = this.editForm.aname
							this.changePro = false
						}
						if (this.faceType) {
							this.editForm.is_face = this.faceType
						}
						let editForm = {
							pid: this.editForm.pid,
							cid: this.editForm.cid,
							aid: this.editForm.aid,
							type: this.editForm.type,
							face_time: this.editForm.face_time,
							id: this.editForm.id,
              open_api: this.editForm.open_api,
							status: this.editForm.status
						}
						//console.log(this.editForm)
						this.$http.post('/driver/faceinfoedit', editForm).then(res => {
							loading.close();
							if (res.data.code === 0) {
								this.face_edit_status = false
								this.$message({
									type: 'success',
									message: res.data.msg
								});
								this.$refs.table.reload();
							} else {
								this.$message.error(res.data.msg);
							}
						}).catch(e => {
							loading.close();
							this.$message.error(e.message);
						});
					} else {
						return false;
					}
				});
			},
			face_start(row) {
					this.$confirm('确定开启人脸检测?', '提示', {type: 'warning'}).then(() => {
						const loading = this.$loading({lock: true});
						this.$http.post('/driver/face_start', {id: row.id}).then(res => {
							loading.close();
							if (res.data.code === 0) {
								this.$message({
									type: 'success',
									message: '开启成功'
								});
								this.$refs.table.reload();
							} else {
								this.$message.error(res.data.msg);
							}
						}).catch(e => {
							loading.close();
							this.$message.error(e.message);
						});
					})
			},
			face_close(row) {
				this.$confirm('确定关闭人脸检测?', '提示', {type: 'warning'}).then(() => {
					const loading = this.$loading({
						lock: true
					});
					this.$http.post('/driver/face_close', {
						id: row.id
					}).then(res => {
						loading.close();
						if (res.data.code === 0) {
							this.$message({
								type: 'success',
								message: '关闭成功'
							});
							this.$refs.table.reload();
						} else {
							this.$message.error(res.data.msg);
						}
					}).catch(e => {
						loading.close();
						this.$message.error(e.message);
					});
				})
			},
      // 是否开启检测接口
      changeStatus(e){
        this.editForm.open_api = e;
      },
			
			
		}
	}
</script>

<style scoped>
	.ele-block .el-upload-dragger {
		width: 100%;
	}

	.el-dialog .el-dialog__body {
		height: 400px;
	}
</style>